import s from './Logo.module.css';

// import logo from './img/logo.svg';
import winterLogo from "./img/winter-logo.svg";

export function Logo() {
    return (
        <a
            href="https://restream.su/"
            target="_blank"
            rel="noreferrer"
        >
            <img
                className={s['logo']}
                src={winterLogo}
                alt="Restream - Мультитрансляции на YouTube, Twitch, VK, Trovo, GG и другие площадки"
            />
        </a>
    );
}