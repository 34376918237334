import { FC, FormEvent, useRef, useState } from "react";

// * Helpers
import {
  IVkStream,
  IVkStreamInfo,
  useSelectVkStreamsMutation,
  VkSelectedItemPlannedBroadcast,
} from "../../../features/api/apiSlice";
import { useParams } from "react-router-dom";

// * Components
import { InputField } from "../../InputField";
import PlatformInstruction from "../PlatformInstruction";
import PlannedFormItemVk from "./PlannedFormItemVk";

// * Props
import VkPlannedFormProps from "./VkPlannedForm.interface";

//* Styles
import styles from "../YoutubePlannedForm/YoutubePlannedForm.module.css";

const VkPlannedForm: FC<VkPlannedFormProps> = ({
  id,
  selectedPlatform,
  closePopup,
}) => {
  const { id: broadcastId } = useParams();
  const [selectVkStreams] = useSelectVkStreamsMutation();
  const [selectedChannels, setSelectedChannels] = useState<VkSelectedItemPlannedBroadcast[]>([]);
  const [filteredPlannedStreams, setFilteredPlannedStreams] = useState<IVkStreamInfo[]>([]);
  const searchInputRef = useRef<HTMLInputElement>(null);
  const plannedStreams = selectedPlatform.plannedStreams as unknown as IVkStream;

  const handleSearchEvent = () => {
    const searchTerm = searchInputRef.current?.value.toLowerCase().trim();

    if (searchTerm) {
      let filteredPlannedProfilesVk: IVkStreamInfo | null = null;

      if (plannedStreams.account.title.toLowerCase().includes(searchTerm)) {
        filteredPlannedProfilesVk = plannedStreams.account;
      }

      const filteredPlannedGroupsVk = plannedStreams.groups
        .filter((stream) => stream.title.toLowerCase().includes(searchTerm));

      if (filteredPlannedProfilesVk) {
        setFilteredPlannedStreams([...filteredPlannedGroupsVk, { ...filteredPlannedProfilesVk }]);
      } else {
        setFilteredPlannedStreams([...filteredPlannedGroupsVk]);
      }
      return;
    }

    setFilteredPlannedStreams([]);
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    selectVkStreams({
      broadcastId: Number(broadcastId),
      streams: selectedChannels,
    });

    closePopup();
  };

  const toggleItem = (stream: VkSelectedItemPlannedBroadcast): void => {
    const streamIndex = findItemIndex(stream);

    if (streamIndex === -1) {
      addItem(stream);
    } else {
      removeItem(streamIndex);
    }
  };

  const addItem = (stream: VkSelectedItemPlannedBroadcast): void => {
    setSelectedChannels(prevItems => [...prevItems, { ...stream }]);
  };

  const removeItem = (streamIndex: number): void => {
    setSelectedChannels(streams => streams.filter((_, index) => index !== streamIndex));
  };

  const findItemIndex = (stream: VkSelectedItemPlannedBroadcast): number => {
    return selectedChannels.findIndex(existingStream => {
      if (stream.type === "channel") {
        return existingStream.type === "channel" && existingStream.owner_id === stream.owner_id;
      } else if (stream.type === "event") {
        return existingStream.type === "event" && existingStream.video_id === stream.video_id;
      }

      return false;
    });
  };

  return (
    <>
      <form id={id} className={styles.streams} onSubmit={(e) => handleSubmit(e)}>
        <PlatformInstruction selectedPlatform={selectedPlatform} />

        <div className={styles.streams__search}>
          <InputField
            ref={searchInputRef}
            type="text"
            className={styles.stream__input}
            placeholder="Поиск каналов..."
            onInput={() => handleSearchEvent()}
          />
        </div>

        {Boolean(searchInputRef.current?.value.trim()) ? (
          <div className={styles.streams__list}>
            <h6 className={styles.streams__heading}>Результат поиска:</h6>

            {filteredPlannedStreams.length ? filteredPlannedStreams.map((stream) => (
              <PlannedFormItemVk
                plannedStream={stream}
                handleSelectEvent={(item) => toggleItem(item)}
                selectedChannels={selectedChannels}
              />
            )) : (
              <div>По вашему запросу ничего не найдено...</div>
            )}
          </div>
        ) : (
          <>
            <div className={styles.streams__list}>
              <h6 className={styles.streams__heading}>Профиль</h6>

              <PlannedFormItemVk
                plannedStream={plannedStreams.account}
                handleSelectEvent={(item) => toggleItem(item)}
                selectedChannels={selectedChannels}
              />
            </div>

            {Boolean(plannedStreams.groups.length) &&
              <div className={styles.streams__list}>
                <h6 className={styles.streams__heading}>Группы</h6>

                {plannedStreams.groups.map((group) => (
                  <PlannedFormItemVk
                    plannedStream={group}
                    handleSelectEvent={(item) => toggleItem(item)}
                    selectedChannels={selectedChannels}
                  />
                ))}
              </div>
            }
          </>
        )}
      </form>
    </>
  );
};

export default VkPlannedForm;
