import { FC, useState } from 'react';

// * helpers
import classNames from 'classnames';

// * components
import Icon from '../../../Icon';

// * props
import VkEventsDropdownProps from './VkEventsDropdown.interface';

// * hooks
import {
	useLazyGetVkPlannedBroadcastQuery,
	VkSelectedItemPlannedBroadcast
} from '../../../../features/api/apiSlice';

// * styles
import styles from './VkEventsDropdown.module.css';

const VkEventsDropdown: FC<VkEventsDropdownProps> = ({ ownerId, onSelect, selectedChannels }) => {
	const [getVkPlannedBroadcast, { data: events }] = useLazyGetVkPlannedBroadcastQuery();
	const [isOpen, setIsOpen] = useState(false);

	const toggleDropdown = () => {
		if (!isOpen) {
			getVkPlannedBroadcast({ owner_id: ownerId });
		}
		setIsOpen(!isOpen);
	};

	const handleSelect = (event: VkSelectedItemPlannedBroadcast) => {
		onSelect(event);
	};

	const isActiveEvent = (videoId: number) => {
		const selectedItem = selectedChannels.find(({ video_id }) => video_id === videoId);
		return Boolean(selectedItem);
	};

	return (
		<div className={styles["vk-events-dropdown__container"]}>
			<div className={styles["vk-events-dropdown__header"]} onClick={toggleDropdown}>
				<div>Запланированные эфиры</div>
				<div
					className={classNames(styles["vk-events-dropdown__arrow"], {
						[styles["vk-events-dropdown__arrow_rotated"]]: isOpen,
					})}
				>
					<Icon name="arrow-down" />
				</div>
			</div>
			{isOpen && (
				<div>
					{events?.length ? (
						<ul className={styles["vk-events-dropdown__list"]}>
							{events.map(({ owner_id, video_id, thumbnails, title }) => (
								<li
									key={owner_id}
									className={classNames(
										styles["vk-events-dropdown__item"], {
										[styles["vk-events-dropdown__item_active"]]: isActiveEvent(video_id),
									})}
									onClick={() => handleSelect({ owner_id, video_id, title, type: "event" })}
								>
									<img className={styles['vk-events-dropdown__img']} src={thumbnails.url} />
									<p className={styles['vk-events-dropdown__name']}>{title}</p>
								</li>
							))}
						</ul>
					) : (
						<div className={styles['vk-events-dropdown__empty']}>Нет доступных запланированных эфиров</div>
					)}
				</div>)}
		</div>
	);
};

export default VkEventsDropdown;
