import { FC } from 'react';

// * Components
import { Link } from '../../Link';
import { Button } from '../../Button';
import { CopyField } from '../../CopyField';
import Icon from '../../Icon';
import TooltipButton from '../../TooltipButton';

// * Props
import { UserInfoProps } from './UserInfo.interface';

// * Hooks
import { useScreenWidth } from '../../../hooks/useScreenWidth';

// * Helpers
import cn from "classnames";
import { formatDate } from '../../../helpers/formatDate';

// * Styles
import styles from "./UserInfo.module.css";

const UserInfo: FC<UserInfoProps> = ({ user }) => {
  const { isMobile } = useScreenWidth();

  return (
    <div className={styles['info']}>

      { !isMobile &&
          <div className={styles['info_row']}>
            <span className={styles['info__field-title']}>Дата регистрации:</span>
            <span>{formatDate(user?.registration_date)}</span>
          </div>
      }

      <div className={styles['info_row']}>
        <span className={styles['info__field-title']}>Тариф:</span>
        <span className={styles['info__tariff']}>
          {user?.tariff.name}
          <div className={styles["info__addition-info"]}>
            <Link to="/tariffs">Сменить тариф</Link>
          </div>
        </span>
      </div>

      <div className={styles['info_row']}>
        <span className={styles['info__field-title']}>Дата оплаты:</span>
        <span>{formatDate(user?.tariff.start_at)}</span>
      </div>

      <div className={styles['info_row']}>
        <span className={styles['info__field-title']}>Дата окончания:</span>
        <span>{user?.tariff.end_at ? formatDate(user?.tariff.end_at) : "Не ограничена"}</span>
      </div>

      <div className={styles['info_row']}>
        <span className={styles['info__field-title']}>Стоимость:</span>
        <span>{user?.tariff?.price ? `${user.tariff.price} р.` : 'Бесплатно'}</span>
      </div>

      <div className={styles['info_row']}>
        <span className={styles['info__field-title']}>Ограничение по битрейту:</span>
        <span>{user?.tariff?.max_input_bandwidth ? `${user.tariff.max_input_bandwidth} мб/с` : '15 мб/с'}</span>
      </div>

      <span>
          { isMobile
            ? <Link to={'/payment-history'}><Button kind="primary">История платежей</Button></Link>
            : <Link to={'/payment-history'}>Посмотреть историю платежей</Link>
          }
      </span>

      <div className={styles['info_row']}>
        <div className={styles['info__field-title']}>Людей приглашено:</div>
        <div className={styles["info__referrals-count-container"]}>
          <span className={styles["info__referrals-count-value"]}>{user?.referrals_count}</span>
          <span className={styles["info__addition-info"]}>
            <TooltipButton
              position={isMobile ? "top-left" : "bottom-center"}
              text="Количество людей, зарегистрировавшихся по вашей ссылке."
            />
          </span>
        </div>
      </div>

      <div className={styles['info_row']}>
        <div className={styles["info_field-title-with-image"]}>
          <span className={cn(styles['info__coins-count-field-title'], styles['info__field-title'])}>
            Баланс
          </span>
          <div className={styles["info__image"]}>
            <Icon name='coin' />
          </div>
        </div>
        <span>{user?.ref_points} балл(ов)</span>
      </div>

      <div>
        <span className={styles['info__field-title']}>Ваша реферальная ссылка:</span>
      </div>

      <span>
        <CopyField
          type="text"
          defaultValue={user?.ref_url}
          readOnly={true}
        />
      </span>
    </div>
  );
};

export default UserInfo;