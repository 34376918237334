import { FC } from "react";

// * Icons
import PlatformIcon from "../../PlatformIcon";

//* Props
import { PlatformInstructionProps } from "./PlatformInstruction.interface";

//* Styles
import styles from "./PlatformInstruction.module.css";

const PlatformInstruction: FC<PlatformInstructionProps> = ({ selectedPlatform }) => {
  const platform = selectedPlatform.platform === "other" ? "restream" : selectedPlatform.platform;
  const instructionLink = selectedPlatform.platform === "other"
    ? "https://restream.su/instruction#rec610353425"
    : selectedPlatform.instructionLink;

  return (
    <div className={styles.instruction}>
      <div className={styles.instruction__icon}>
        <PlatformIcon platform={platform} />
      </div>

      <p className={styles.instruction__text}>
        Прочтите инструкцию по подключению к сервису
        <a
          href={instructionLink}
          className={styles.instruction__link}
          target="_blank"
          rel="noreferrer"
        >
          здесь
        </a>
      </p>
    </div>
  );
};

export default PlatformInstruction;
