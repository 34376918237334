import { FC } from "react";

// * Props
import { PlannedFormItemVkProps } from "./PlannedFormItemVk.interface";

// * Components
import PlatformIcon from "../../../PlatformIcon";
import VkEventsDropdown from "../VkEventsDropdown";

// * Helpers
import cn from "classnames";

// * Styles
import styles from "./PlannedFormItemVk.module.css";

const PlannedFormItemVk: FC<PlannedFormItemVkProps> = ({
  handleSelectEvent,
  plannedStream,
  selectedChannels,
}) => {
  const {id: ownerId} = plannedStream;

  const handleSelectItem = () => {
    handleSelectEvent({
      type: "channel",
      owner_id: ownerId,
      title: plannedStream.title
    });
  };

  const isActive = () => {
    const selectedItem = selectedChannels.find(({ owner_id, video_id }) => owner_id === ownerId && !video_id);
    return Boolean(selectedItem);
  };

  return (
    <div className={styles.stream}>
      <div
        className={cn(styles["stream__container"],
          {[styles.stream_selected]: isActive()}
        )}
        onClick={() => handleSelectItem()}
      >
        {plannedStream.thumbnails.url ? (
            <img className={styles.stream__img} src={plannedStream.thumbnails.url} />
          ) : (
            <div className={styles.stream__image}>
              <PlatformIcon platform={"vk"} />
            </div>
          )
        }
        <div className={styles.stream__info}>
          {plannedStream.title}
        </div>
      </div>
      <div className={styles["stream__events-dropdown-container"]}>
        <VkEventsDropdown
          ownerId={ownerId}
          onSelect={(event) => handleSelectEvent(event)}
          selectedChannels={selectedChannels}
        />
      </div>
    </div>
  );
};

export default PlannedFormItemVk;
